import React, { useLayoutEffect, Suspense }from 'react';
import { Route, Switch, Redirect, useLocation} from 'react-router-dom';
import './index.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { NotFound } from './Components/NotFound';

const Navbar = React.lazy(() => import('./Components/Navbar'));
const Home = React.lazy(() => import('./Components/Home'));
const Contact = React.lazy(() => import('./Components/Contact'));
const Service = React.lazy(() => import('./Components/Service'));
const AboutUs = React.lazy(() => import('./Components/AboutUs'));
const Portfolio = React.lazy(() => import('./Components/Portfolio'));
const Footer = React.lazy(() => import('./Components/Footer'));

function App() {

  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  };
  
  return (
    <> 
    <Wrapper>
      <Navbar/>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch basename="/home">
          <Route exact path="/home" component={Home} />
          <Route exact path="/service" component={Service} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/contact" component={Contact} />
          <Redirect exact from="/" to="/home"/>
          <Route exact path="*" component={NotFound} />
        </Switch>
        </Suspense>
      <Footer/>
    </Wrapper>
    </>
  );
}

export default App;
